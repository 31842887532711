import { useState } from 'react';
import emailjs from '@emailjs/browser';
import { StyledDiv } from './styledFalecomNos';
import { data } from '../data';

export default function ContatoConteudo() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  function sendEmail(e) {
    e.preventDefault();
    if (name === '' || email === '' || message === '') {
      alert("Preencha todos os campos");
      return;
    }

    const templateParams = {
      from_name: name,
      message: message,
      email: email
    };

    emailjs.send("service_24ho9h3", "template_qk5zqgb", templateParams, "2auHZo3PNvCes7hWt") // Alterar para o email do cliente
      .then((response) => {
        console.log("Email enviado:", response.status, response.text);
        alert("Email enviado com sucesso!");
        setName('');
        setEmail('');
        setMessage('');
      })
      .catch((error) => {
        console.error("Erro ao enviar email:", error);
        alert("Erro ao enviar email. Por favor, tente novamente mais tarde.");
      });
  }

  return (
    <StyledDiv>
      <a id='contato'></a>
      <div className="contatos-container">
        <span>{data.contatos.faleConosco}</span>
        <h1>{data.contatos.titulo}</h1>
        <p>{data.contatos.texto}</p>

        <div className='redesSociais'>
          <ul>
            <li><a href={data.topoMenu.linkInstagran} target="_blank" rel='noopener noreferrer'><img src={data.topoMenu.imagemInsta} alt="imagemInsta" /></a></li>
            {/*<li><a href={data.topoMenu.linkLinkedin} target="_blank" rel='noopener noreferrer' ><img src={data.topoMenu.imagemLinkedin} alt="imagemLinkedin" /> </a></li>
            <li><a href={data.topoMenu.linkYoutube} target="_blank" rel='noopener noreferrer' ><img src={data.topoMenu.imagemYoutube} alt="imagemYoutube" /> </a></li>*/}
            <li><a href={data.topoMenu.linkfacebook} target="_blank" rel='noopener noreferrer'><img src={data.topoMenu.imagemfacebook} alt="imagemfacebook" /></a></li>
          </ul>
        </div>
      </div>

      <div className="form-container">
        <form className="form" onSubmit={sendEmail}>
          <h3>Enviar email</h3>
          <input
            className="input"
            type="text"
            placeholder="Digite seu nome"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          <input
            className="input"
            type="text"
            placeholder="Digite seu email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <input
            className="input"
            type="number"
            placeholder="Digite seu telefone com DDD"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          />
          <input className="button" type="submit" value="Enviar" />
        </form>
      </div>
    </StyledDiv>
  );
}
