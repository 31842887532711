import styled from 'styled-components';
import { data } from '../data';

export const StyledTopo = styled.div`
    display: flex;
    position: fixed;
    z-index: 4;
    width: 100%;
    justify-content: space-between;
    background-color: #fff; /* Fundo branco para o topo */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Sombra sutil */
    
    opacity: 0; /* Inicialmente invisível */
    transition: opacity 1s ease; /* Transição suave */
   
    ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        li {
            margin-right: 10px;
            padding-left: 10px;

            img {
                max-width: 25px; /* Tamanho dos ícones */
                width: 100%;
                height: auto; /* Mantém a proporção */
            }
        }
    }

    @media screen and (max-width: 768px) {
        ul {
            
            align-items: flex-start;
        }
        a {
            font-size: 12px; /* Ajusta o tamanho da fonte */
        }
    }

    &.visible {
         
        opacity: 1; /* Torna o topo visível */
    }
`;

export const StyledApresentacao = styled.div`
 
 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh; /* Altura para ocupar a tela inteira */
 
    background: linear-gradient(135deg, #FFF700, #FFE135, #FFD700, #FFC300);
    background-size: cover;
    position: relative;
    opacity: 0; /* Inicialmente invisível */
    transition: opacity 1s ease; /* Transição suave */

    .fotoEdson {
        position: relative;
        top: 20px; /* Ajusta a posição */
        z-index: 55;
        width: 200px;
        height: 200px; /* Mantém a proporção */
        object-fit: cover;
        border-radius: 50%;
        background-color: #FFE60D;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3); /* Sombra sutil */
    }

    .containerLogo {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: black;
        width: 90%;
        padding: 20px;
        border-radius: 10px; /* Bordas arredondadas */
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3); /* Sombra ao container */
        
        img {
            max-width: 100%; /* Ajusta o tamanho da logo */
            height: auto; /* Mantém a proporção */
        }
    }

    button {
        padding: 10px 20px;
        background-color: white;
        border: none;
        border-radius: 30px;
        cursor: pointer;
        transition: background-color 0.3s;
        font-size: 20px;
        margin-top: 20px; /* Espaçamento superior */

        &:hover {
            background-color: #FFE004; /* Cor ao passar o mouse */
        }
    }

    &.visible {
        opacity: 1; /* Torna a apresentação visível */
    }

    @media screen and (max-width: 700px) {
         
        .fotoEdson {
            width: 150px; /* Ajusta o tamanho para telas menores */
            height: 150px;
            top: 10px;
        }
        .containerLogo {
            height: auto;
            padding: 10px;
        }
        button {
            font-size: 18px; /* Ajusta o tamanho da fonte */
        }
    }

    @media screen and (max-width: 420px) {
        height: 70vh;
        .fotoEdson {
            width: 120px; /* Ajusta ainda mais para telas pequenas */
            height: 120px;
        }
        .containerLogo {
            height: auto;
            padding: 5px;
           
        }
        button {
            font-size: 16px; /* Ajusta o tamanho da fonte para telas muito pequenas */
        }
        
    }
    @media screen and (max-width: 345px){
        height: 50vh;
    }
`;
