import styled from "styled-components";


export const StyledLocal = styled.div`
text-align: center;

iframe{
    position: relative;
 padding: 10px;
margin-bottom: 50px;
    width: 70%;
     height: 400px;
     box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
border-radius: 10px;
}
`