import React, { useState, useEffect } from 'react';
import { StyledApresentacao, StyledTopo } from "./styleTopo";
import { data } from "../data";
import imagemEdson from './fotoedson.png';
import logo from './logo.jpg';

export default function Topo() {
    const [topoVisible, setTopoVisible] = useState(false);
    const [apresentacaoVisible, setApresentacaoVisible] = useState(false);

    useEffect(() => {
        const topoTimer = setTimeout(() => {
            setTopoVisible(true);
        }, 200);

        const apresentacaoTimer = setTimeout(() => {
            setApresentacaoVisible(true);
        }, 200);

        return () => {
            clearTimeout(topoTimer);
            clearTimeout(apresentacaoTimer);
        };
    }, []);

    const handleScrollToServicos = () => {
        const servicosElement = document.getElementById('servicos');
        servicosElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    return (
        <>
            <StyledTopo className={topoVisible ? 'visible' : ''}>
                <ul>
                    <li><a href={data.topoMenu.linkInstagran} target="_blank" rel='noopener noreferrer'><img src={data.topoMenu.imagemInsta} alt="logoinstagran" /></a></li>
                    <li><a href={data.topoMenu.linkfacebook} target="_blank" rel='noopener noreferrer'><img src={data.topoMenu.imagemfacebook} alt="logoFacebook" /></a></li>
                </ul>
                <ul>
                    <li><a href={`mailto:${data.topoMenu.email}?subject=Assunto do Email&body=Corpo do Email`}>Email</a></li>
                    <li><a href={`tel:${data.topoMenu.telefone}`}>Ligar: {data.topoMenu.telefone}</a></li>
                </ul>
            </StyledTopo>
            <StyledApresentacao className={apresentacaoVisible ? 'visible' : ''}>
                <img className='fotoEdson' src={imagemEdson} alt="Professor Edson Constantino" />
                <div className='containerLogo'>
                    <img src={logo} alt="Logo" />
                </div>
                <button onClick={handleScrollToServicos}>{data.topoMenu.botao}</button>
            </StyledApresentacao>
        </>
    );
}
